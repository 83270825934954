<template>
  <div>
    <p class="text--title mb-5 mt-15 custom-title">
      {{ title }}
    </p>
    <!-- List checkbox (Date of stay) -->
    <div class="rounded elevation-3 pa-6">
      <p class="title-table">
        {{ $t('contractDetail.selectTheDateOfStay') }}
      </p>
      <table class="t-table">
        <thead>
          <tr>
            <th></th>
            <th v-for="item in schedules" :key="item.stayDate">
              <p class="title-table">{{ formatDate(item.stayDate) }}</p>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td
              class="t-item mr-5 custom-item"
              style="margin: 0; width: 130px; align-items: unset !important"
            >
              <div v-for="(item, i) in guests" :key="'x' + i">
                <span
                  style="
                    padding-top: 6px;
                    height: 75px;
                    display: block;
                  "
                  v-if="item.representative"
                  ><p class="mb-0 title-table">宿泊代表者</p>
                  <div v-if="!item.name" style="max-height:16px !important; height:16px !important">
                    <p class="title-table">{{ item.name }}</p>
                  </div>
                  <div v-else >
                    <p class="title-table">{{ item.name }}</p>
                  </div>
                  </span>
              </div>

              <div>
                <span v-for="(item, i) in guests" :key="i">
                  <div class="t-item__el">
                    <p
                      class="title-table"
                      style="
                        margin: 0 !important;
                        height: 75px;
                        padding-top: 8px;
                      "
                      v-if="!item.representative"
                    >
                      <span v-if="!item.child">
                        宿泊者{{ i + 1 }}(大人)
                      </span>
                      <span v-else-if="item.child && item.bedShared === false">
                        宿泊者{{ i + 1 }} (子供(非添い寝))
                      </span>
                      <span v-else>
                        宿泊者{{ i + 1 }} (子供(添い寝))
                      </span>
                    </p>
                  </div>
                </span>
              </div>
            </td>
            <td v-for="(item, index) in schedules" :key="index">
              <div
                class="t-item align-center"
                style="margin: 0 !important; height: 75px"
                v-for="(item, i) in item.item"
                :key="'c' + i"
              >
                <v-checkbox
                  @change="changeData(item, $event)"
                  :input-value="item[prop]"
                  @input="item[prop] = $event"
                  hide-details
                  :disabled="disabled"
                ></v-checkbox>
              </div>
              <span class="t-item__el ml-6"
                ><p class="custom-count">{{ count(item) }}</p></span
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- End list checkbox (Date of stay)-->
  </div>
</template>

<script>
import moment from 'moment'

export default {
  props: {
    title: String,
    schedules: Array,
    guests: Array,
    prop: String,
    disabled: Boolean,
  },
  methods: {
    changeData (item, value) {
      item[this.prop] = value
      this.$emit('changeData', { ...item })
    },
    count (items) {
      var count = 0
      for (let i = 0; i < items.item.length; i++) {
        if (items.item[i][this.prop]) {
          count = count + 1
        }
      }
      return count
    },
    formatDate (value) {
      var d = moment(value).format('dddd')
      switch (d) {
        case 'Sunday':
          d = '日'
          break
        case 'Monday':
          d = '月'
          break
        case 'Tuesday':
          d = '火'
          break
        case 'Wednesday':
          d = '水'
          break
        case 'Thursday':
          d = '木'
          break
        case 'Friday':
          d = '金'
          break
        case 'Saturday':
          d = '土'
          break
      }
      return moment(value).format('DD日 (' + d + ')')
    },
  },
}

</script>

<style lang="scss" scoped>
::v-deep {
  .t-table {
    overflow-x: auto;
    width: 100%;
    display: block;
    font-size: 14px;
    thead > tr > th {
      padding: 0 10px;
      font-weight: 500;
      white-space: nowrap;
    }
    tbody > tr > td:first-child {
      width: 80px;
    }

    .t-item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &__el:not(:last-child), .v-input {
        height: 40px;
        margin: 0;
        margin-bottom: 15px;
      }
      span.t-item__el {
        font-weight: 600;
        font-size: 16px;
        margin-right: 7px;
      }
    }
  }
}
</style>
