<template>
  <div class="px-5 px-sm-15 pt-5 py-10">
    <div class="text-right mb-8">
      <v-btn large class="btn--red-dark" href="javascript:window.open('','_self').close();">
        <v-icon class="mr-2 font-weight-inherit">mdi-close</v-icon>
        {{ $t("buttons.close") }}
      </v-btn>
      <v-btn :disabled="!checkPerUser" large class="btn--primary ml-3" @click="$withDelay(actionUpdate)">
        {{ $t("buttons.save") }}
      </v-btn>
    </div>
    <v-row>
      <v-col cols="12" lg="6" class="pr-0 pr-lg-6">
        <p class="text--title mb-5 custom-title">
          {{ $t("common.numOfPersons") }}
        </p>
        <div class="d-flex" :key="idx">
          <div>
            <p class="name-select">{{ $t("common.adult") }}</p>
            <v-select
              @change="changeSelectNotChild = $event"
              :value="countNotChild"
              :items="numOfAdultOptions"
              hide-details
              class="pa-0"
              :disabled="!checkPerUser"
            >
            </v-select>
          </div>
          <div class="mx-8">
            <p class="name-select">
              {{
                $t("common.children") + "(" + $t("common.unaccompanied") + ")"
              }}
            </p>
            <v-select
              @change="changeSelectChidNotBedShared = $event"
              :value="countChildNotBedShared"
              :items="numOfChildrenOptions"
              hide-details
              class="pa-0"
              :disabled="!checkPerUser"
            >
            </v-select>
          </div>
          <div>
            <p class="name-select">
              {{ $t("common.children") + "(" + $t("common.accompanied") + ")" }}
            </p>
            <v-select
              @change="changeSelectChidBedShared = $event"
              :items="numOfChildrenOptions"
              :value="countChildBedShared"
              hide-details
              class="pa-0"
              :disabled="!checkPerUser"
            >
            </v-select>
          </div>
        </div>
        <StayDateCheckbox
          :title="$t('contractDetail.actualDayOfStay')"
          :message="$t('contractDetail.selectTheDateOfStay')"
          :schedules="listGuestSchedules"
          :guests="listBooking"
          :disabled="!checkPerUser"
          prop="stay"
          @changeData="changeData"
        />
        <StayDateCheckbox
          v-if="showBathingSchedule"
          :title="$t('contractDetail.dateOfBathing')"
          :schedules="listGuestSchedules"
          :guests="listBooking"
          :disabled="!checkPerUser"
          prop="bathing"
          @changeData="changeData"
        />
        <StayDateCheckbox
          v-if="hotelTaxCheckboxEnabled"
          :title="$t('contractDetail.dateOfHotelTaxSubject')"
          :schedules="listGuestSchedules"
          :guests="listBooking"
          :disabled="!checkPerUser"
          prop="hotelTaxSubject"
          @changeData="changeData"
        />
        <!-- End list checkbox (Date of bathing)-->
      </v-col>
      <v-col cols="12" lg="6" class="pl-0 pl-lg-12">
        <p class="text--title mb-5 custom-title">
          {{ $t("contractDetail.guestInfomation") }}
        </p>
        <div v-for="(item, i) in listBooking" :key="'x' + i" class="mb-3">
          <div v-if="item.representative">
            <p class="mb-2 title-table">
              {{ $t("contractDetail.accommodationRepresentative") }}
            </p>
            <div style="color: #444444">{{ item.name }}</div>
          </div>
        </div>
          <div v-for="(item, i) in listBooking" :key="i" class="mb-3">
            <v-expansion-panels class="mt-10">
              <v-expansion-panel>
                <div v-if="!item.representative">
                  <v-expansion-panel-header>
                    <span v-if="!item.child" class="subtitle-1"> 宿泊者{{ i + 1 }} (大人) </span>
                    <span v-else-if="item.child && item.bedShared" class="subtitle-1"> 宿泊者{{ i + 1 }} (子供(添い寝)) </span>
                    <span v-else class="subtitle-1"> 宿泊者{{ i + 1 }} (子供(非添い寝)) </span>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <p class="title-table">
                      {{ $t("contractDetail.nameOrMember") }}
                    </p>
                    <v-combobox
                      @change="getChangeSubName($event, item)"
                      v-model="item.name"
                      item-text="name"
                      item-value="name"
                      :items="listSubMembers"
                      hide-details
                      class="pa-0 w-50 custom-select"
                      :disabled="!checkPerUser"
                    >
                    </v-combobox>

                    <p class="mt-5 title-table">
                      住所
                    </p>
                    <v-combobox
                      @change="getChangeAddess($event, item)"
                      :value="item.address1"
                      :items="addressItem"
                      hide-details
                      class="pa-0"
                      :disabled="!checkPerUser"
                    >
                    </v-combobox>
                    <div class="d-flex">
                      <div>
                        <p class="mt-5 title-table">
                          {{ $t("common.occupation") }}
                        </p>
                        <v-text-field
                          @change="getChangeOccupation($event, item)"
                          v-model="item.occupation"
                          hide-details
                          class="pa-0"
                          :disabled="!checkPerUser"
                        >
                        </v-text-field>
                      </div>
                      <div class="d-flex">
                        <div class="mx-3">
                          <p class="mt-5 title-table">{{ $t("common.age") }}</p>
                          <v-select
                            @change="getChangeAge($event, item)"
                            v-model="item.age"
                            :items="listAge"
                            hide-details
                            class="pa-0"
                            :disabled="!checkPerUser"
                          >
                          </v-select>
                        </div>
                        <div>
                          <p class="mt-5 title-table">
                            {{ $t("common.gender") }}
                          </p>
                          <guest-gender-select
                            @change="getChangeSex($event, item)"
                            v-model="item.sex"
                            class="pa-0"
                            :disabled="!checkPerUser"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="d-flex">
                      <div class="w-50 mr-3">
                        <p class="mt-5 title-table">
                          {{ $t("contractDetail.emailAddress") }}
                        </p>
                        <v-text-field
                          @change="getChangeEmail($event, item)"
                          v-model="item.email"
                          class="pa-0"
                          :disabled="!checkPerUser"
                        >
                        </v-text-field>
                      </div>
                      <div class="w-50 ml-3">
                        <p class="mt-5 title-table">
                          {{ $t("contractDetail.telephoneNumNonRep") }}
                        </p>
                        <v-text-field
                          @change="getChangeTel($event, item)"
                          v-model="item.tel"
                          hide-details
                          class="pa-0"
                          :disabled="!checkPerUser"
                        >
                        </v-text-field>
                      </div>
                    </div>

                    <div class="w-50">
                      <p class="mt-5 title-table">
                        {{ $t("common.nationality") }}
                      </p>
                      <v-select
                        class="pa-0"
                        @change="getNationality($event, item)"
                        v-model="item.nationality"
                        :items="['日本', 'その他']"
                        hide-details
                        :disabled="!checkPerUser"
                      >
                      </v-select>
                    </div>

                    <p class="mt-5 title-table">
                      {{ $t("contractDetail.passportOrResidenceImage") }}
                    </p>
                    <v-file-input
                      @change="getChangePhoto($event, item)"
                      :placeholder="item.passportPhoto"
                      :rules="uploadRules"
                      accept="image/png, image/jpeg, image/bmp"
                      prepend-icon="mdi-camera"
                      :disabled="!checkPerUser"
                    ></v-file-input>
                    <v-card
                      class="pl-3 bg-contain"
                      height="150px"
                      width="250px"
                      outlined
                      :img="item.passportPhotoUrl"
                      v-if="item.passportPhotoUrl"
                    />
                    <p class="mt-5 title-table">
                      {{ "パスポート・在留資格番号(画像ない場合)" }}
                    </p>
                    <v-text-field
                      @change="getChangePassPort($event, item)"
                      hide-details
                      class="pa-0"
                      v-model="item.passportNumber"
                      :disabled="!checkPerUser"
                    >
                    </v-text-field>
                  </v-expansion-panel-content>
                </div>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  BOOKING,
  CLIENT_MEMBER_INFO
} from '@/api/graphql/bookingDetail/booking-guest'
import { UPLOAD_PASSPORT_URL } from '@/api/graphql/bookingDetail/setting-cancel'
import axios from 'axios'

import _ from 'lodash'
import moment from 'moment'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { checkPermissionUserCurrent } from '@/utils/permissions'
import gql from 'graphql-tag'
import GuestGenderSelect from '../../../../components/Input/GuestGenderSelect.vue'
import { getDateRangeArray } from '@/utils/dateUtil'
import StayDateCheckbox from './StayDateCheckbox.vue'

export default {
  name: 'GuestDetails',
  data () {
    return {
      idx: 0,
      booking: null,
      changeSelectNotChild: null,
      changeSelectChidBedShared: null,
      changeSelectChidNotBedShared: null,
      hotSpringTax: null,
      checkPerUser: checkPermissionUserCurrent(this.$router.currentRoute),
      disable: true,
      statusUpdate: false,
      listDataGuestSchedule: [],
      guestWithSchedule: [],
      arrUpdate: [],
      arrSchedue: [],
      arrListItemInfo: [],
      arrUpdateLeft: [],
      arrUpdateRight: [],
      itemGender: '',
      schedules: [],
      bookingListRight: [],
      showBathingSchedule: false,
      hotelTaxCheckboxEnabled: false,
      subMemberId: null,
      address1: '',
      occupation: '',
      age: null,
      sex: '',
      email: '',
      tel: '',
      passportPhoto: '',
      passportNumber: '',
      fileUrl: '',
      listUserSort: [],
      addressItem: ['代表者と同じ'],
      addressRepresentative: '',
      listGuestSchedules: [],
      countNotChild: 0,
      countChildBedShared: 0,
      countChildNotBedShared: 0,
      listSubMembers: [],
      subMembers: [],
      clientId: null,
      listBooking: [],
      adultNum: 3,
      childUnaccompanied: 0,
      childAccompanied: 0,
      maxOccupancy: 7,
      listAge: [],
      uploadRules: [
        (value) =>
          !value || value.size < 2000000 || '2MBを超えることはできません'
      ],
      representative: null
    }
  },
  watch: {
    async getStatusReload () {
      this.listBooking = []
      this.arrUpdateRight = []
      this.arrUpdateLeft = []
      this.listDataGuestSchedule = []
      this.arrSchedue = []
      this.bookingListRight = []
      this.countNotChild = 0
      this.countChildBedShared = 0
      this.countChildNotBedShared = 0
      await this.getBookingGuest()
      this.changeSelectNotChild = this.countNotChild
      this.changeSelectChidBedShared = this.countChildBedShared
      this.changeSelectChidNotBedShared = this.countChildNotBedShared
      this.idx += 1
    }
  },

  computed: {
    ...mapGetters(['getStatusReload']),
    numOfAdultOptions () {
      return [...Array(this.maxOccupancy)].map((_, i) => i + 1)
    },
    numOfChildrenOptions () {
      const adults = this.numOfAdultOptions
      adults.unshift(0)
      return adults
    },
    stayDateRange () {
      if (!this.booking) return []
      if (this.booking.permanentBlock) return [] // this page shouldn't be shown for block, but just in case. without this it will send a request to the server and fail (too much data).
      return getDateRangeArray(this.booking.checkInDate, this.booking.checkOutDate, { excludeLast: true })
    }
  },

  mounted () {
    this.getBookingGuest()
    this.getListData()
  },

  methods: {
    ...mapMutations({
      setBookingGuest: 'setBookingGuest',
      setBookingGuestSchedules: 'setBookingGuestSchedules',
      setLoadingOverlayHide: 'setLoadingOverlayHide',
      setLoadingOverlayShow: 'setLoadingOverlayShow'
    }),
    ...mapActions(['bookingGuestUpdate']),
    pushOrReplaceToRight (item) {
      const found = this.arrUpdateRight.findIndex(data => data.id === item.id)
      if (found >= 0) {
        this.arrUpdateRight[found] = item
      } else {
        this.arrUpdateRight.push(item)
      }
    },
    getChangeSubName (event, item) {
      if (this.listSubMembers.includes(event)) {
        if (event.id !== 99999) {
          item.subMemberId = parseInt(event.id)
          item.clientId = null
          item.name = event.name
          this.pushOrReplaceToRight(item)
        } else {
          item.subMemberId = null
          item.clientId = parseInt(event.clientId)
          item.name = event.name
          this.pushOrReplaceToRight(item)
        }
      } else {
        item.name = event
        item.clientId = null
        item.subMemberId = null
        this.pushOrReplaceToRight(item)
      }
    },

    getDataSexField (item) {
      if (item === 'Male') {
        this.itemGender = '男性'
      } else if (item === 'Female') {
        this.itemGender = '女性'
      } else {
        this.itemGender = 'その他'
      }
    },

    getChangeAddess (event, item) {
      if (event === '代表者と同じ') {
        item.address1 = this.addressRepresentative
      } else {
        item.address1 = event
      }
      this.pushOrReplaceToRight(item)
    },

    getChangeOccupation (event, item) {
      this.occupation = event
      this.pushOrReplaceToRight(item)
    },

    getChangeAge (event, item) {
      this.age = event
      this.pushOrReplaceToRight(item)
    },

    getChangeSex (event, item) {
      this.pushOrReplaceToRight(item)
    },

    getChangeEmail (event, item) {
      this.email = event
      if (event === '') {
        item.email = null
      }
      this.pushOrReplaceToRight(item)
    },

    getChangeTel (event, item) {
      this.tel = event
      this.pushOrReplaceToRight(item)
    },

    getNationality (event, item) {
      this.nationality = event
      this.pushOrReplaceToRight(item)
    },

    getChangePhoto (event, item) {
      if (event !== null) {
        const theReader = new FileReader()
        theReader.onloadend = async () => {
          item.passportPhotoUrl = await theReader.result
        }
        theReader.readAsDataURL(event)
        this.$apollo
          .mutate({
            mutation: UPLOAD_PASSPORT_URL,
            variables: {
              contentType: event.type,
              contentLength: event.size
            }
          })
          .then((data) => {
            item.passportPhoto = data.data.generateUploadUrl.key
            const uploadUrl = data.data.generateUploadUrl.uploadUrl
            this.passportPhoto = data.data.generateUploadUrl.key
            axios.put(
              uploadUrl,
              event,
              {
                headers: {
                  'Content-Type': event.type
                }
              }
            )
            this.pushOrReplaceToRight(item)
          })
          .catch((error) => {
            console.error(error)
          })
      } else {
        item.passportPhoto = ''
        this.passportPhoto = ''
        this.pushOrReplaceToRight(item)
      }
    },

    getChangePassPort (event, item) {
      this.passportNumber = event
      this.pushOrReplaceToRight(item)
    },

    setDefalut () {
      if (this.arrUpdateRight.length === 0) {
        this.arrUpdateRight = this.arrListItem
      }

      if (this.arrUpdateLeft.length === 0) {
        var arrNew = []
        for (let i = 0; i < this.listDataGuestSchedule.length; i++) {
          delete this.listDataGuestSchedule[i].__typename
          arrNew.push(this.listDataGuestSchedule[i])
        }
        this.arrUpdateLeft = arrNew
      }
    },

    async actionUpdate () {
      const createSchedules = (child) => {
        const schedules = this.stayDateRange.map((stayDate) => {
          return {
            stayDate,
            bathing: !child,
            stay: true,
          }
        })
        return { schedules }
      }

      this.arrUpdate = []
      this.arrUpdateLeft = _.chain(this.arrUpdateLeft)
        .groupBy('guestId')
        .map((value, key) => ({ guestId: key, items: value }))
        .value()
      var objUpdate = {}
      var arrayStayDate = []
      var B = []
      for (let i = 0; i < this.arrUpdateLeft.length; i++) {
        B = []
        objUpdate = {}
        arrayStayDate = []
        for (let j = this.arrUpdateLeft[i].items.length - 1; j >= 0; j--) {
          if (!arrayStayDate.includes(this.arrUpdateLeft[i].items[j].stayDate)) {
            arrayStayDate.push(this.arrUpdateLeft[i].items[j].stayDate)
            B.push(this.arrUpdateLeft[i].items[j])
          }
        }
        objUpdate.schedules = B
        objUpdate.id = parseInt(this.arrUpdateLeft[i].guestId)
        this.arrSchedue.push(objUpdate)
      }
      let repSchedules = null
      if (this.representative) {
        repSchedules = this.arrSchedue.find(s => s.id === this.representative.id)?.schedules
      }
      if (!repSchedules) {
        repSchedules = createSchedules(false).schedules
      }
      var arrayId = []
      var C = []
      for (let i = this.arrUpdateRight.length - 1; i >= 0; i--) {
        if (!arrayId.includes(this.arrUpdateRight[i].id)) {
          arrayId.push(this.arrUpdateRight[i].id)
          C.push(this.arrUpdateRight[i])
        }
      }
      for (let i = 0; i < C.length; i++) {
        for (let j = 0; j < this.arrSchedue.length; j++) {
          if (C[i].id === this.arrSchedue[j].id) {
            var objfomat = C[i]
            objfomat.schedules = this.arrSchedue[j]
            this.arrUpdate.push(objfomat)
          }
        }
      }
      this.guestWithSchedule = []
      for (let i = 0; i < this.arrUpdate.length; i++) {
        if (this.arrUpdate[i].__typename) {
          delete this.arrUpdate[i].__typename
        }

        this.guestWithSchedule.push(this.arrUpdate[i])
      }
      var arrUpdateId = []
      var D = []
      for (let i = this.guestWithSchedule.length - 1; i >= 0; i--) {
        if (!arrUpdateId.includes(this.guestWithSchedule[i].id)) {
          arrUpdateId.push(this.guestWithSchedule[i].id)
          D.push(this.guestWithSchedule[i])
        }
      }

      const itemNotChild = {
        address1: null,
        age: null,
        bedShared: false,
        child: false,
        clientId: null,
        email: null,
        name: null,
        nationality: null,
        occupation: null,
        passportNumber: null,
        passportPhoto: null,
        sex: null,
        tel: null,
        schedules: createSchedules(false)
      }

      const childNotSleep = {
        address1: null,
        age: null,
        bedShared: false,
        child: true,
        clientId: null,
        email: null,
        name: null,
        nationality: null,
        occupation: null,
        passportNumber: null,
        passportPhoto: null,
        sex: null,
        tel: null,
        schedules: createSchedules(true)
      }

      const childSleep = {
        address1: null,
        age: null,
        bedShared: true,
        child: true,
        clientId: null,
        email: null,
        name: null,
        nationality: null,
        occupation: null,
        passportNumber: null,
        passportPhoto: null,
        sex: null,
        tel: null,
        schedules: createSchedules(true)
      }
      let originalNonChildCount = this.countNotChild
      if (!this.representative) originalNonChildCount++
      if (this.changeSelectNotChild > originalNonChildCount) {
        for (let i = 0; i < (this.changeSelectNotChild - originalNonChildCount); i++) {
          D.push(itemNotChild)
        }
      } else if (this.changeSelectNotChild < originalNonChildCount) {
        for (let j = 0; j < originalNonChildCount - this.changeSelectNotChild; j++) {
          for (let i = D.length - 1; i >= 0; i--) {
            if (!D[i].child) {
              D.splice(i, 1)
              break
            }
          }
        }
      }

      if (this.changeSelectChidBedShared > this.countChildBedShared) {
        for (let i = 0; i < (this.changeSelectChidBedShared - this.countChildBedShared); i++) {
          D.push(childSleep)
        }
      } else if (this.changeSelectChidBedShared < this.countChildBedShared) {
        for (let j = 0; j < this.countChildBedShared - this.changeSelectChidBedShared; j++) {
          for (let i = D.length - 1; i >= 0; i--) {
            if (D[i].child && D[i].bedShared) {
              D.splice(i, 1)
              break
            }
          }
        }
      }

      if (this.changeSelectChidNotBedShared > this.countChildNotBedShared) {
        for (let i = 0; i < (this.changeSelectChidNotBedShared - this.countChildNotBedShared); i++) {
          D.push(childNotSleep)
        }
      } else if (this.changeSelectChidNotBedShared < this.countChildNotBedShared) {
        for (let j = 0; j < this.countChildNotBedShared - this.changeSelectChidNotBedShared; j++) {
          for (let i = D.length - 1; i >= 0; i--) {
            if (D[i].child && !D[i].bedShared) {
              D.splice(i, 1)
              break
            }
          }
        }
      }
      await this.setBookingGuestSchedules({ guestSchedules: D, repSchedules })
      const result = await this.bookingGuestUpdate()
      if (result.data.bookingGuestUpdate.needsTvpAdjustment) {
        this.$confirm({
          message: '宿泊料金を上回ったTVP数が登録されています。 利用指定TVP数をすべて消費するようTVP数を変更してください。',
          okText: '請求画面へ移動',
          ok: () => {
            this.$router.push({
              path: '/reservation/contract-detail',
              query: {
                id: this.$route.query.id,
                tab: 'billing-statement'
              }
            })
          },
          cancelText: 'この画面に留まる'
        })
      }
      this.statusUpdate = true
    },

    changeData (item) {
      delete item.__typename
      this.arrUpdateLeft.push(item)
    },

    countStay (items) {
      var countStay = 0
      for (let i = 0; i < items.item.length; i++) {
        if (items.item[i].stay) {
          countStay = countStay + 1
        }
      }
      return countStay
    },

    countBathing (items) {
      var countBathing = 0
      for (let i = 0; i < items.item.length; i++) {
        if (items.item[i].bathing) {
          countBathing = countBathing + 1
        }
      }
      return countBathing
    },

    getListData () {
      for (let i = 1; i < 121; i++) {
        this.listAge.push(i)
      }
    },

    async getBookingGuest () {
      this.setLoadingOverlayShow()
      await this.$apollo
        .query({
          query: gql`${BOOKING}`,
          variables: {
            id: parseInt(this.$route.query.id)
          },
          fetchPolicy: 'no-cache'
        })
        .then(async (data) => {
          this.booking = data.data.booking
          this.setLoadingOverlayHide()
          this.changeSelectNotChild = 0
          this.changeSelectChidBedShared = 0
          this.changeSelectChidNotBedShared = 0
          this.showBathingSchedule = data.data.booking.room.roomType.facility.showBathingSchedule
          this.hotelTaxCheckboxEnabled = data.data.booking.room.roomType.facility.hotelTaxCheckboxEnabled
          this.maxOccupancy = data.data.booking.room.roomType.maxOccupancy
          this.arrUpdateLeft = []
          this.arrUpdateRight = []
          this.bookingListRight = data.data.booking.guests
          this.listDataGuestSchedule = data.data.booking.guestSchedules
          // const listbook = _.sortBy(data.data.booking.guests, 'id')
          const audultList = []
          const childNoBedSharedList = []
          const childWithBedSharedList = []
          data.data.booking.guests.forEach(item => {
            if (!item.child) {
              audultList.push(item)
            } else if (item.child && item.bedShared === false) {
              childNoBedSharedList.push(item)
            } else {
              childWithBedSharedList.push(item)
            }
          })
          this.listBooking = [...audultList, ...childNoBedSharedList, ...childWithBedSharedList]
          this.representative = this.listBooking.find((item) => item.representative)
          const arr = this.listBooking.filter((item) => !item.representative)

          this.arrListItem = JSON.parse(JSON.stringify(arr))
          for (let i = 0; i < data.data.booking.guests.length; i++) {
            if (data.data.booking.guests[i].representative) {
              if (data.data.booking.guests[i].address1) {
                this.addressRepresentative = data.data.booking.guests[i].address1
              } else this.addressItem = []
              this.clientId = data.data.booking.guests[i].clientId
              this.representId = data.data.booking.guests[i].id
            }

            if (!data.data.booking.guests[i].child) {
              this.countNotChild++
            } else {
              if (data.data.booking.guests[i].bedShared) {
                this.countChildBedShared++
              } else {
                this.countChildNotBedShared++
              }
            }
          }
          this.changeSelectNotChild = this.countNotChild
          this.changeSelectChidBedShared = this.countChildBedShared
          this.changeSelectChidNotBedShared = this.countChildNotBedShared

          const sortDate = _.sortBy(
            data.data.booking.guestSchedules,
            'stayDate'
          )
          const guestIdToIndex = this.listBooking.reduce((result, guest, ind) => {
            result[guest.id] = ind
            return result
          }, {})
          const sortDateAndId = sortDate.sort((a, b) => guestIdToIndex[a.guestId] - guestIdToIndex[b.guestId])
          const result = _.chain(sortDateAndId)
            .groupBy('stayDate')
            .map((value, key) => ({ stayDate: key, item: value }))
            .value()
          const result2 = _.sortBy(result, 'stayDate')
          this.getListName()
          this.listGuestSchedules = result2
          this.setDefalut()
          this.disable = false
        })

        .catch((error) => {
          this.setLoadingOverlayHide()
          console.error(error)
        })
    },

    async getListName () {
      if (this.clientId) {
        await this.$apollo
          .query({
            query: gql`${CLIENT_MEMBER_INFO}`,
            variables: {
              id: this.clientId
            }
          })
          .then((data) => {
            // this.listSubMembers = data.data.clientMemberInfo.subMembers
            this.listSubMembers = data.data.clientMemberInfo.subMembers
            const item = {
              id: 99999,
              clientId: data.data.clientMemberInfo.id,
              name: data.data.clientMemberInfo.name,
              nameKana: data.data.clientMemberInfo.nameKana,
              email: data.data.clientMemberInfo.email,
              tel: data.data.clientMemberInfo.telPreference,
              address1: ''
            }
            this.listSubMembers.unshift(item)
          })
          .catch((error) => {
            console.error(error)
          })
      }
    },

    formatDate (value) {
      var d = moment(value).format('dddd')
      switch (d) {
        case 'Sunday':
          d = '日'
          break
        case 'Monday':
          d = '月'
          break
        case 'Tuesday':
          d = '火'
          break
        case 'Wednesday':
          d = '水'
          break
        case 'Thursday':
          d = '木'
          break
        case 'Friday':
          d = '金'
          break
        case 'Saturday':
          d = '土'
          break
      }
      return moment(value).format('DD日 (' + d + ')')
    }
  },

  components: { GuestGenderSelect, StayDateCheckbox }
}
</script>

<style lang="scss" scoped>
.custom-item-name {
  height: 75px;
  p {
    margin: 0 !important;
  }
}

.subtitle-1 {
  font-size: 14px !important;
  color: #232222;
}
.name-select {
  color: #000000;
  font-size: 10px;
}
::v-deep {
  .custom-title {
    font-size: 20px !important;
    font-weight: bold;
  }
  .title-table {
    font-size: 10px;
    color: #000000;
    font-weight: bold !important;
  }
  .custom-count {
    font-size: 20px;
    color: #000000;
    margin-bottom: 0;
    margin-left: 20px;
  }
}
::v-deep {
  .v-text-field__slot input {
    font-size: 14px !important;
    color: #444444;
  }
  .v-select__selection--comma {
    color: #444444 !important;
  }
  .theme--light {
    color: #757575;
  }
  .v-file-input__text--placeholder {
    font-size: 13px;
    color: #606060 !important;
  }
  .w-50 {
    width: 50%;
    max-width: 50%;
  }
}
</style>
